import React, { useEffect, useState } from "react";
interface RiskCountDetail {
  risk_name: string;
  count: number;
}
interface RiskDetail {
  target_file: string;
  similarity_score: string;
  source_risk_content: string;
  target_risk_content: string;
  highlighted_differences: string;
}

interface RiskSummary {
  [category: string]: RiskDetail[];
}

interface RiskTabsProps {
  riskSummary: RiskSummary;
  sourceFileName?: string;
  riskCountData?: RiskCountDetail[];
}

const RiskTabs: React.FC<RiskTabsProps> = ({
  riskSummary,
  sourceFileName,
  riskCountData,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [count, setCount] = useState(0);
  const riskCategories = Object.keys(riskSummary).sort();

  useEffect(() => {
    const selectedCategory = riskCategories[activeTab];
    if (Array.isArray(riskCountData)) {
      const category = riskCountData.find(
        (item) => item.risk_name === selectedCategory
      );
      const categoryRiskCount = category ? category.count : 0;
      setCount(categoryRiskCount);
    } else if (typeof riskCountData === "object") {
      const categoryRiskCount = riskCountData[selectedCategory] || 0;
      setCount(categoryRiskCount);
    }
  }, [activeTab, riskSummary, riskCountData, riskCategories]);

  const applyStrikethrough = (text: string): string => {
    return text?.replace(
      /<strike>(.*?)<\/strike>/g,
      '<del style="color: red;">$1</del>'
    );
  };

  return (
    <div>
      {/* Tab Navigation */}
      <div
        className="flex space-x-4 overflow-x-auto overflow-y-hidden border-b border-gray-300 mb-4 max-w-full"
        style={{
          scrollbarWidth: "thin",
          scrollbarColor: "#3b82f6 #f1f1f1",
        }}
      >
        {riskCategories.length > 0 ? (
          riskCategories.map((category, index) => (
            <button
              key={index}
              className={`py-3 px-6 text-lg font-medium rounded-t-lg transition-all duration-300 ease-in-out transform hover:scale-105 whitespace-nowrap ${
                activeTab === index
                  ? "text-white bg-blue-600 shadow-lg border-b-4 border-blue-800"
                  : "text-gray-600 hover:text-blue-600 hover:bg-gray-200"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {category}
            </button>
          ))
        ) : (
          <div>No risks available</div>
        )}
      </div>

      {/* Tab Content */}
      <div className="p-6 bg-white rounded-lg shadow-lg border border-gray-300">
        {riskCategories.map((category, index) => (
          <div
            key={category}
            className={activeTab === index ? "block" : "hidden"}
          >
            <h2 className="text-xl font-bold text-blue-700 mb-4">{category}</h2>
            <div>
              <p className="font-semibold text-lg my-2 text-gray-800">
                Found Risk Version : {count}{" "}
              </p>
            </div>
            {riskSummary[category].map((detail, i) => (
              <div
                key={i}
                className="p-5 mb-6 bg-gray-50 rounded-lg shadow-sm border border-gray-200 hover:shadow-lg transition-all duration-300"
              >
                <div className="text-gray-800 font-bold text-lg mb-3">
                  <span className="text-green-600">Source File: </span>
                  <span>{sourceFileName}</span>
                </div>
                <div className="text-gray-700 mb-3">
                  <span className="font-medium">Compared File: </span>
                  <span>{detail.target_file}</span>
                </div>
                <div className="text-gray-700 mb-3">
                  <span className="font-medium">Similarity: </span>
                  <span>{detail.similarity_score}</span>
                </div>

                <div className="py-4">
                  <span className="font-medium text-gray-800">
                    Source Risk Content:
                  </span>
                  <p
                    className="text-sm text-gray-700 whitespace-pre-line mt-2"
                    dangerouslySetInnerHTML={{
                      __html: applyStrikethrough(detail.source_risk_content),
                    }}
                  ></p>
                </div>

                <div className="py-4">
                  <span className="font-medium text-gray-800">
                    Target Risk Content:
                  </span>
                  <p
                    className="text-sm text-gray-700 whitespace-pre-line mt-2"
                    dangerouslySetInnerHTML={{
                      __html: applyStrikethrough(detail.target_risk_content),
                    }}
                  ></p>
                </div>

                <div className="py-4">
                  <span className="font-medium text-gray-800">
                    Differences:
                  </span>
                  <p
                    className="text-sm text-gray-700 whitespace-pre-line mt-2"
                    dangerouslySetInnerHTML={{
                      __html: applyStrikethrough(
                        detail?.highlighted_differences ?? "No differences "
                      ),
                    }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RiskTabs;
