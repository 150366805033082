import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { IconCloudUpload, IconFileTypePdf, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import Header from "../../dashboard/Header";
import { downloadXmlFile } from "./DownloadXmlFile";
import RiskTabs from "./RiskTabs";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ReactSlider from "react-slider";

interface FileWithPreview {
  file: File;
  preview: string;
}
interface Comparison {
  Differences: string;
  compared_file: string;
  similarity: string;
}
interface RiskSummary {
  source_risk: string;
  risk_name: string;
  risk_differences: Record<string, string>;
  similarity_count: number;
  comparisons: Record<string, Comparison>;
}

interface RiskCountDetail {
  risk_name: string;
  count: number;
  documents: string[];
}
const MultipleFileUploadXml: React.FC = () => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [riskCountData, setRiskCountData] = useState<RiskCountDetail[]>([]);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const [riskSummary, setRiskSummary] = useState({});
  // const [riskSummary, setRiskSummary] = useState<RiskSummary[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  // const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [sourceFileName, setSourceFileName] = useState("");
  const [fileList, setFileList] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(0);

  // const handleToggle = (index: number) => {
  //   setActiveIndex(activeIndex === index ? null : index);
  // };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const fileList = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFiles((prevFiles) => [...prevFiles, ...fileList]);
  }, []);

  const accept = { "application/pdf": [] };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  const handleRemove = (fileToRemove: FileWithPreview) => {
    setFiles(files.filter((file) => file.file.name !== fileToRemove.file.name));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  const handleMultipleUpload = async () => {
    setLoadingFiles(true);
    const formData = new FormData();
    files.forEach(({ file }) => {
      formData.append("files", file);
    });
    axios
      .post(`${apiUrl}/upload_files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response) {
          getFileList();
        }
        setLoadingFiles(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getFileList = () => {
    axios
      .get(`${apiUrl}/list_uploaded_files`, {})
      .then((response) => {
        console.log(response?.data.uploaded_files);
        setFileList(response?.data?.uploaded_files);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    return () => {
      files.forEach(({ preview }) => URL.revokeObjectURL(preview));
    };
  }, [files]);

  const handleClear = () => {
    setFiles([]);
    setRiskCountData([]);
    setRiskSummary([]);
    setIsView(false);
    setSourceFileName("");
    handleClearUploadFolder();
    setFileList([]);
    setSourceFileName("");
    setLoading(false);
    setThresholdValue(0);
  };

  const handleBack = () => {
    navigate("/dashboard");
    setFiles([]);
    setRiskCountData([]);
    setRiskSummary([]);
    setIsView(false);
    handleClearUploadFolder();
    setFileList([]);
    setSourceFileName("");
    setLoading(false);
    setThresholdValue(0);
  };

  const handleDownload = () => {
    downloadXmlFile();
  };

  const handleChange = (selectedFile: any) => {
    setSourceFileName(selectedFile.value);
  };

  const handleRiskAnalyze = async (filename: string) => {
    setRiskCountData([]);
    setIsView(true);
    setLoading(true);
    setError(null);
    const thresholdInDecimal = thresholdValue / 100;

    await axios
      .post(`${apiUrl}/compare_risks`, null, {
        params: {
          source_file_name: filename,
          threshold: thresholdInDecimal,
        },
      })
      .then((response) => {
        console.log(response.data);
        setRiskCountData(response?.data?.unique_risk_counts);
        setRiskSummary(response?.data?.comparisons);
        setSourceFileName(response?.data?.source_file);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClearUploadFolder = async () => {
    await axios
      .post(`${apiUrl}/clear_upload_folder`, null)
      .then((response) => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSliderChange = (newValue: number) => {
    setThresholdValue(newValue);
    // handleRiskAnalyze(sourceFileName)
  };

  return (
    <div className="h-screen ">
      <Header />
      <div className="p-2">
        <button
          type="button"
          onClick={handleBack}
          className="px-4 py-2 w-24 bg-blue-700 text-white rounded-lg hover:bg-blue-500 flex items-center justify-center"
        >
          Back
        </button>
      </div>
      <div className="flex flex-col items-center">
        <div {...getRootProps({ className: "w-full max-w-2xl" })}>
          <div>
            <span className="text-4xl font-bold text-blue-700">
              Broadridge AI Analysis Risks Extraction
            </span>
            <h1 className="mt-8  text-lg font-semibold text-blue-700">
              Upload PDFs
            </h1>
          </div>

          <input {...getInputProps()} />
          <label
            htmlFor="file-upload"
            className="flex flex-col  items-center justify-center h-full cursor-pointer"
          >
            <div className="relative flex items-center justify-between p-2 w-full h-24 border-2 rounded-lg bg-sky-50">
              <div className="flex items-center">
                <IconCloudUpload
                  stroke={2}
                  color="grey"
                  className="h-14 w-14"
                />
                <div className="ml-4 flex flex-col">
                  <span className="font-normal">Drag & drop PDFs here.</span>
                </div>
              </div>
              <button
                type="button"
                disabled={riskCountData?.length !== 0 || loading}
                onClick={() => document.getElementById("file-upload")?.click()}
                className={`w-36 px-4 py-2 text-white rounded-lg bg-blue-600 ${
                  riskCountData?.length !== 0 || loading
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
              >
                Browse Files
              </button>
            </div>
          </label>
        </div>
        <div className="flex flex-col items-center mt-4">
          <div className="flex space-x-4">
            <button
              type="button"
              onClick={handleMultipleUpload}
              disabled={loading || files?.length < 2}
              className={`mt-2 ${
                loading ? "w-48" : "w-36"
              } px-4 py-2 text-white rounded-lg bg-blue-600 ${
                riskCountData?.length !== 0 || files?.length < 2
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              } `}
            >
              {loadingFiles ? "Uploading..." : "Upload"}
            </button>
            <button
              type="button"
              onClick={handleClear}
              className={`mt-2 w-36 px-4 py-2 text-white rounded-lg bg-blue-600`}
            >
              Clear
            </button>
          </div>
        </div>
        {error && <div className="text-red-500 mt-2">{error}</div>}

        {/* Display Uploaded Files */}
        <div className="flex items-center justify-center   max-h-96 w-2/4 p-4 overflow-auto">
          <div className="flex flex-col w-full max-w-2xl ">
            {files?.map(({ file, preview }) => (
              <div
                key={file.name}
                className="relative flex items-center justify-between p-2 mb-2 w-full border-2 rounded-lg bg-sky-50 "
              >
                <div className="flex items-center">
                  <IconFileTypePdf stroke={1} className="h-6 w-6" />
                  <div className="ml-4 flex flex-col">
                    <a
                      href={preview}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500"
                    >
                      {file.name}
                    </a>
                  </div>
                </div>
                {!riskCountData && (
                  <IconX
                    stroke={1}
                    className="text-gray-600 h-4 w-4 cursor-pointer"
                    onClick={() => handleRemove({ file, preview })}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div
          className={`flex flex-col items-center justify-between w-full max-w-2xl mx-auto p-1 ${
            riskCountData?.length !== 0 || loading ? "mb-10" : "mb-60"
          }`}
        >
          {fileList?.length !== 0 && (
            <>
              <div className="flex w-full justify-between space-x-4">
                <div className="flex flex-col w-2/3 pr-2">
                  <label
                    htmlFor="source-file"
                    className="text-lg font-semibold text-gray-700 mb-2"
                  >
                    Select the Source File
                  </label>
                  <Dropdown
                    options={fileList}
                    value={sourceFileName}
                    onChange={handleChange}
                    placeholder="Select Source File"
                    className="rounded-lg w-full bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                  />
                </div>

                <div className="w-1/3">
                  <div className="w-full max-w-lg mx-auto">
                    <label
                      htmlFor="threshold"
                      className="text-lg font-semibold text-gray-700 mb-2 mt-2"
                    >
                      Threshold
                    </label>
                    <ReactSlider
                      step={5}
                      ariaLabelledby="slider-label"
                      className="horizontal-slider relative w-full h-2 bg-gray-200 rounded-full mt-4"
                      thumbClassName="example-thumb w-6 h-6 bg-blue-600 rounded-full shadow-lg transform transition-all duration-300 ease-out hover:scale-110 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      trackClassName="example-track h-3 bg-gradient-to-r from-blue-400 to-blue-600 rounded-full transition-all"
                      renderThumb={(props, state) => (
                        <div
                          {...props}
                          className="flex items-center justify-center font-semibold"
                          style={{
                            fontSize: "15px",
                            width: "24px",
                            height: "35px",
                            marginTop: "10px",
                            position: "relative",
                          }}
                        >
                          {state.valueNow}
                        </div>
                      )}
                      onChange={handleSliderChange}
                      value={thresholdValue}
                    />
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={() => handleRiskAnalyze(sourceFileName)}
                className={`w-1/3 mt-10 px-4 py-2 text-white rounded-lg bg-blue-600 ${
                  sourceFileName === "" ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={sourceFileName === ""}
              >
                {loading ? "Analyzing risks..." : "Analyze risks"}
              </button>
            </>
          )}
        </div>

        {/* Display Extracted Risk Count */}
        {files && files?.length > 0 && isView && (
          <>
            {riskCountData?.length !== 0 ? (
              <>
                <div className="container mx-auto p-6">
                  <div className="flex items-center justify-between  mb-2">
                    <h1 className="text-xl font-bold text-blue-700">
                      Risk Summary
                    </h1>
                    <button
                      type="button"
                      onClick={handleDownload}
                      className={`mt-2 w-36 px-4 py-2 text-white rounded-lg bg-blue-600 `}
                    >
                      Download
                    </button>
                  </div>
                  <RiskTabs
                    riskSummary={riskSummary}
                    sourceFileName={sourceFileName}
                    riskCountData={riskCountData}
                  />
                </div>
              </>
            ) : (
              <div role="status" className="flex justify-center mt-6 ">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MultipleFileUploadXml;
